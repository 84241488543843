.Modal-perm{
    display: flex;
    // gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    label{
        color: #333;
    
    }

    h1{
        font-family: Edensor, serif;
        color: #333;
        font-weight: 500;
        font-size: 40px;
    }

    .PhoneInput{
        width:49%;
    }

    input{
        width:49%;
        padding: 10px 30px;
        margin-bottom: 10px;
        border: 1px solid var(--darkFontColor);
    }

    

    .PhoneInputCountry{
        border: 1px solid var(--darkFontColor);
        padding: 10px;
        height: 82%;
    }

    textarea{
        width: 100%;
        min-height: 100px;
        max-height: 100px;
        min-width: 100%;
        padding: 10px 30px;
        margin-bottom: 10px;
        border: 1px solid var(--darkFontColor);
    }
}

@media screen and (max-width: 850px){
    .Modal-perm{
        display: flex;
        flex-direction: column;

        label{
            color: #333;
        
        }

        input{
            width:100%;
            padding: 10px 30px;
            margin-bottom: 10px;
            border: 2px solid var(--darkFontColor);
        }

        .PhoneInput{
            width:100%;
        }


        .PhoneInputCountry{
            border: 2px solid var(--darkFontColor);
            padding: 10px;
            height: 82%;
        }

        textarea{
            width: 100%;
            min-height: 80px;
            max-height: 80px;
            min-width: 100%;
            padding: 10px 30px;
            margin-bottom: 10px;
            border: 2px solid var(--darkFontColor);
        }
    }
}