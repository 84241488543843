@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import url('https://fonts.googleapis.com/css2?family=Castoro+Titling&family=Great+Vibes&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');

@font-face {
    font-family: "Belgiano";
    src: url('../../assets/fonts/Belgiano.otf') format('opentype');
}

@font-face {
    font-family: "Edensor";
    src: url('../../assets/fonts/Edensor.otf') format('opentype');
}

