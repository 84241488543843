/* Eds Normalize */
@use '../abstracts/variables' as *;
@use '../parameters/_mixins' as *;

* {
	@include default_normalize;
	&::after {
		@include default_normalize;
	}
	&::before {
		@include default_normalize;
	}
	&::selection {
		background-color: var(--primaryColor);
		color: #fff;
	}
	&::-webkit-scrollbar-thumb {
		background-color: var(--scrollColor);
		border-radius: 4px;
		opacity: 0.1;
	}
	&::-webkit-scrollbar-corner {
		border-radius: 20px;
	}
	&::-webkit-scrollbar {
		padding: 20px 0px;
		background: var(--fillInput);
		width: 6px;
		&:hover {
			background: var(--fillInput);
			background: var(--fillInput);
		}
	}
}

.section-container {
	margin-top: 100px;
}

body {
	background-color: #fff;
	overflow-x: hidden;
	background-size: cover;
	// font-family: "DM Sans", sans-serif;
	font-family: "Poppins", sans-serif;
	width: 100vw;
	overflow-x: hidden;
}

body.modal-overlay {
	overflow: hidden;
}
body.modal.Large {
	overflow: hidden;
}

// Mixins input-form 
@mixin input-default {
	border: 1px solid var(--whiteColor);
    outline: none;
    background-color: var(--whiteColor);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 10px 30px;
	margin-bottom: 15px;
    width: 100%;
    transition: all ease-in-out .4s;

    &:hover, &:focus {
        border: 1px solid var(--primaryColor) !important;
    }

	&.error {
		box-shadow: 5px 5px 4px rgba(245, 21, 21, 0.5);
	}
}

@mixin dropdown-default {
	position: absolute;
	margin-top: 0px;
	z-index: 200;
	padding: 10px;
	width: 100%;
	display: none;
	opacity: 0;
	border-radius: 20px;
	flex-direction: column;
	background-color: #fff;
	transition: all .5s ease-in-out;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

// Classe pour les Input login
.input-control {
	border: 1px solid var(--whiteColor);
    outline: none;
    background-color: var(--whiteColor);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 10px 30px;
    width: 100%;
    transition: all ease-in-out .4s;
    text-align: center;

    &::placeholder {
        text-align: center;
    }

    &:hover, &:focus {
        border: 1px solid var(--primaryColor) !important;
    }

	&.error {
		box-shadow: 5px 5px 4px rgba(245, 21, 21, 0.5);
	}
}

// Classe pour les Input
.input-form {    
	@include input-default;
	height: 50px;
}


// Dropdown bouton
.dropdown-button {
	width: 100%;
	position: relative;
	height: 50px !important;
	margin-bottom: 10px;
	
	.dropdown-body {
		font-family: "Calibri";
	}

	.dropdown-title {
		border: 1px solid var(--whiteColor);
		outline: none;
		height: 100%;
		background-color: var(--whiteColor);
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 20px;
		padding: 10px 30px;
		margin-bottom: 15px;
		width: 100%;
		transition: all ease-in-out .4s;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: gray;
		cursor: pointer;
	
		&:hover, &:focus {
			border: 1px solid var(--primaryColor) !important;
		}
	}

	.selected{
		color: #000;
	}

	.dropdown-list {
		@include dropdown-default
	}

	.dropdown-icon {
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	.dropdown-item {
		cursor: pointer;
		padding: 5px 15px;
		margin: 5px;
		border-radius: 5px;

		&:hover {
			background-color: #d8d8d8;
		}
	}

	&.open .fa-sort-desc{
		transform: rotate(180deg);
	}

	&.open .dropdown-list {
		display: flex;
		opacity: 1;
	}
}

.table-container {
	width: 100%;
	align-items: center;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

	.empty-commande {
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.text {
		text-align: center;
	}
}

.text-center {
    text-align: center;
}

// Classs pour les boutons
.btn-submit {
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--whiteColor);
	background-color: var(--primaryColor);	
	border-radius: 20px;
	padding: 10px 30px;
	width: 100%;
	font-weight: bold;
	cursor: pointer;
    transition: all ease-in-out .4s;
	
	&:hover {
		background-color: var(--greyColor) !important;
		color: var(---darkColor)
	}
}

// Classs pour les boutons
.btn-success {
	color: var(--whiteColor);
	background-color: var(--secondaryColor);	
	border-radius: 20px;
	padding: 10px 30px;
	width: 100%;
	font-weight: bold;
	cursor: pointer;
    transition: all ease-in-out .4s;
	
	&:hover {
		background-color: var(--greyColor) !important;
		color: var(---darkColor)
	}
}

.ellipsis {  
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}