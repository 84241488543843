:root {
    /* principal color */
    --primaryColor: #1aa5e6;
    --secondaryColor: #7289DA;
    --thirdColor: #0B2A97;

    --disabledColor: #7f7f7f;
    --whiteColor: #ffffff;
    --fillInput: #fff;
    --greyColor: #EFF5F7;

    /* background color*/
    --backgroundColor: #F9F9F9;

    /* Transparent color */
    --whiteColorTransparent: rgba(255, 255, 255, 0.7);
    --darkColorTransparent: rgba(0, 0, 0, 0.08);
    --scrollColor: rgba(0, 0, 0, 0.3);

    /* Font color */
    --darkFontColor: #333;
    --lightFontColor: #808285;

    /* Success color */
    --succesColor: #7bdcb5;
    --darksuccesColor: #00d084;

    /* Warning color */
    --warningColor: #e6aa03;
    --darkwarningColor: #fcb900;

    /* Danger color */
    --dangerColor: #f54545;
    --darkdangerColor: #d23013;

    /* linear color */
    --linearColor1: linear-gradient(to right, #0b4e88 0%, #1d5306 80%); 
    --linearColor2: linear-gradient(to right, #d85dc1 10%, #7289DA 100%);
    --transitionColor: linear-gradient(180deg, rgba(247, 247, 247,0) 0%, rgba(247, 247, 247,1) 100%);

    /* Supplement color */
    --color1: #FFB000;
    --color2: #D30074;
    --color3: #e96e16;
    --color4: #06b91e;
}

.darktheme {
    /* Principal color */
    --primaryColor: #d85dc1;
    --secondaryColor: #7289DA;
    --thirdColor: #0B2A97;

    --disabledColor: #7f7f7f;
    --whiteColor: #141414;
    --fillInput: #222222;
    --greyColor: #191919;

    /* Background color*/
    --backgroundColor: #191919;

    /* Transparent color */
    --whiteColorTransparent: rgba(255, 255, 255, 0.7);
    --darkColorTransparent: rgba(255, 255, 255, 0.08);
    --scrollColor: rgba(255, 255, 255, 0.3);

    /* Font color */
    --darkFontColor: #ffffff;
    --lightFontColor: #b8b8b8;

    /* Success color */
    --succesColor: #7bdcb5;
    --darksuccesColor: #00d084;

    /* Warning color */
    --warningColor: #e6aa03;
    --darkwarningColor: #fcb900;

    /* Danger color */
    --dangerColor: #f54545;
    --darkdangerColor: #d23013;

    /* Linear color */
    --linearColor1: linear-gradient(to right, #00AEEF 0%, #d85dc1 100%); 
    --linearColor2: linear-gradient(to right, #d85dc1 10%, #7289DA 100%);
    --transitionColor: linear-gradient(180deg, rgba(247, 247, 247,0) 0%, rgba(247, 247, 247,1) 100%);

    /* Supplement color */
    --color1: #FFB000;
    --color2: #D30074;
    --color3: #e96e16;
    --color4: #06b91e;
}
